export const dataURLtoFile = (dataURL, filename) => {
  // Obtener la parte de datos del data URL
  const arr = dataURL.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // Convertir la cadena de datos en un blob
  while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
  }
  const blob = new Blob([u8arr], { type: mime });

  // Crear un objeto File a partir del blob
  return new File([blob], filename, { type: mime });
};
